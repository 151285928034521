<template>
  <dv-charts v-if="trendsData.length > 0" :option="option" />
</template>

<script>
export default {
  name: 'PatientLine',
  props: {
    trendsData: {
      type: Array,
      default: () => []
    }
  },
  computed: {

    option() {
      let xAxisData = [], seriesData = []
      if (this.trendsData) {
        this.trendsData.forEach( element => {
          xAxisData.push(element.key)
          seriesData.push(element.intValue)
        })
      }
      return {
        legend: {
          data: ['患者趋势'],
          textStyle: {
            fill: '#fff'
          }
        },
        xAxis: {
          data: xAxisData,
          boundaryGap: false,
          axisLine: {
            style: {
              stroke: '#999'
            }
          },
          axisLabel: {
            style: {
              fill: '#999'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          data: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            style: {
              stroke: '#999'
            }
          },
          axisLabel: {
            style: {
              fill: '#999'
            },
            formatter ({ value }) {
              return value.toFixed(2)
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            name: '患者趋势',
            smooth: true,
            lineArea: {
              show: true,
              gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
            },
            linePoint: {
              radius: 4,
              style: {
                fill: '#00db95'
              }
            }
          }
        ]
      }
    }
  }
}
</script>
