<template>
  <v-container
    class="blue-grey darken-4 align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="text-h3 white--text text-center"
      >
        {{ $vuetify.lang.t('$vuetify.monitorScreen.summaryLevelTitle1') }}
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="4"
      >
        <v-responsive :aspect-ratio="16/9">
          <dv-border-box-10>
            <patient-line :trends-data="patientTrendsData"/>
          </dv-border-box-10>
        </v-responsive>
      </v-col>

      <v-col
        cols="4"
      >
        <dv-border-box-10>
          <v-row>
            <v-col
              cols="12"
              class="text-h4 mt-2 white--text text-center"
            >
              {{ $vuetify.lang.t('$vuetify.monitorScreen.sosDataTitle') }}
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center"
            >
              {{ $vuetify.lang.t('$vuetify.monitorScreen.sosDataTotalNum') }}
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center"
            >
              {{ $vuetify.lang.t('$vuetify.monitorScreen.sosDataTodayNum') }}
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center py-0"
            >
              <dv-digital-flop :config="sosDataTotalNum" style="height: 50px;"/>
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center py-0"
            >
              <dv-digital-flop :config="sosDataTodayNum" style="height: 50px;"/>
            </v-col>

             <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center"
            >
              {{ $vuetify.lang.t('$vuetify.monitorScreen.sosDataWeekNum') }}
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center"
            >
              {{ $vuetify.lang.t('$vuetify.monitorScreen.sosDataMonthNum') }}
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center py-0"
            >
              <dv-digital-flop :config="sosDataWeekNum" style="height: 50px;"/>
            </v-col>

            <v-col
              cols="6"
              class="text-h5 mt-2 white--text text-center py-0"
            >
              <dv-digital-flop :config="sosDataMonthNum" style="height: 50px;"/>
            </v-col>

          </v-row>
        </dv-border-box-10>
      </v-col>

      <v-col
        cols="4"
      >
        <v-responsive :aspect-ratio="16/9">
          <dv-border-box-10>
            <equ-online-line :trends-data="equOnlineTrendsData"/>
          </dv-border-box-10>
        </v-responsive>
      </v-col>

    </v-row>

    <v-row>
      <v-col
        cols="3"
      >
        <v-responsive :aspect-ratio="16/9">
          <dv-border-box-10>
            <v-row
              class="pb-3"
            >
              <v-col
                cols="12"
                class="text-h4 mt-2 white--text text-center"
              >
                全县患者数据统计
              </v-col>

              <v-col
                cols="12"
                class="text-h5 mt-2 white--text text-center"
              >
                纳入规范化管理总人数（实时）
              </v-col>

              <v-col
                cols="12"
                class="text-h5 mt-2 white--text text-center py-0"
              >
                <dv-digital-flop :config="realtimeDataPatientNum" style="height: 50px;"/>
              </v-col>

            </v-row>
          </dv-border-box-10>
        </v-responsive>

        <v-responsive class="mt-3" :aspect-ratio="16/9">
          <dv-border-box-10>
            <v-row>
              <v-col
                cols="12"
                class="text-h4 mt-2 white--text text-center"
              >
                各乡镇管理人数排名
              </v-col>
              <v-col>
                <dv-capsule-chart :config="patientDataTopData" />
              </v-col>
            </v-row>
          </dv-border-box-10>
        </v-responsive>

        <v-responsive class="mt-3" :aspect-ratio="16/9">
          <dv-border-box-10>
            <v-row>
              <v-col
                cols="12"
                class="text-h4 mt-2 white--text text-center"
              >
                全县设备数据统计
              </v-col>

              <v-col
                cols="12"
                class="text-h5 mt-2 white--text text-center"
              >
                智能设备接入数量（实时）
              </v-col>

              <v-col
                cols="12"
                class="text-h5 mt-2 white--text text-center py-0"
              >
                <dv-digital-flop :config="realtimeDataDeviceNum" style="height: 50px;"/>
              </v-col>

            </v-row>
          </dv-border-box-10>
        </v-responsive>


      </v-col>

      <v-col
        cols="6"
      >
        <dv-border-box-10>
          <v-chart :options="options"/>
        </dv-border-box-10>
      </v-col>

      <v-col
        cols="3"
      >
        <v-responsive :aspect-ratio="16/9">
          <dv-border-box-10>
            <v-row>
              <v-col
                cols="12"
                class="text-h4 mt-2 white--text text-center"
              >
                各乡镇处理呼救排名（总）
              </v-col>
              <v-col>
                <dv-capsule-chart :config="sosDataTopData" />
              </v-col>
            </v-row>
          </dv-border-box-10>
        </v-responsive>

        <v-responsive class="mt-3" :aspect-ratio="16/9">
          <dv-border-box-10>
            <v-row
              class="pb-3"
            >
              <v-col
                cols="12"
                class="text-h4 mt-2 white--text text-center"
              >
                健康报告统计
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center"
              >
                共产生报告数量
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center"
              >
                健康综合报告数量
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center py-0"
              >
                <dv-digital-flop :config="reportDataTotalNum" style="height: 50px;"/>
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center py-0"
              >
                <dv-digital-flop :config="reportDataHealthNum" style="height: 50px;"/>
              </v-col>


            </v-row>
          </dv-border-box-10>
        </v-responsive>

        <v-responsive class="mt-3" :aspect-ratio="16/9">
          <dv-border-box-10>
            <v-row>
              <v-col
                cols="12"
                class="text-h4 mt-2 white--text text-center"
              >
                心电报告统计
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center"
              >
                标准心电报告数量
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center"
              >
                专家心电报告数量
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center py-0"
              >
                <dv-digital-flop :config="realtimeDataPatientNum" style="height: 50px;"/>
              </v-col>

              <v-col
                cols="6"
                class="text-h5 mt-2 white--text text-center py-0"
              >
                <dv-digital-flop :config="realtimeDataPatientNum" style="height: 50px;"/>
              </v-col>

            </v-row>
          </dv-border-box-10>
        </v-responsive>


      </v-col>

    </v-row>

    <v-row>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="血压比例">
          <dv-charts v-if="currentSummaryLevel1" :option="bloodPressureData" />
        </dv-border-box-11>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="血氧比例">
          <dv-charts v-if="currentSummaryLevel1" :option="bloodOxygenData" />
        </dv-border-box-11>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="运动量">
          <dv-charts v-if="currentSummaryLevel1" :option="sportData" />
        </dv-border-box-11>
      </v-col>

      <v-col
        cols="3"
        class="text-h3 white--text text-center"
        style="height:350px;"
      >
        <dv-border-box-11 title="睡眠质量">
          <dv-charts v-if="currentSummaryLevel1" :option="option" />
        </dv-border-box-11>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import PatientLine from './components/PatientTrendsDataLine'
  import EquOnlineLine from './components/EquOnlineTrendsDataLine'


  import 'echarts/map/js/china.js';
  import 'echarts/map/js/province/anhui.js';

  export default {
    components: {
      PatientLine,
      EquOnlineLine
    },
    name: 'app',

    data () {
      let data = []

      for (let i = 0; i <= 360; i++) {
          let t = i / 180 * Math.PI
          let r = Math.sin(2 * t) * Math.cos(2 * t)
          data.push([r, i])
      }
      return {
        defaultDigitalFlopConfig: {
          number: [0],
          content: '{nt}',
          style: {
            fontSize: 50,
            fill: '#3de7c9'
          }
        },
        defaultCapsuleChartConfig: {
          data: [],
          unit: ' ',
          showValue: true,
          valueFormatter ({ value }) {
            console.warn(arguments)
            const reverseNumber = (value + '').split('').reverse()
            let valueStr = ''

            while (reverseNumber.length) {
              const seg = reverseNumber.splice(0, 3).join('')
              valueStr += seg
              if (seg.length === 3) valueStr += ','
            }

            return valueStr.split('').reverse().join('')
          }
        },
        defaultBloodPressureDataChartConfig: {
          series: [
              {
                  name: '血压',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },
        defaultBloodOxygenDataChartConfig: {
          series: [
              {
                  name: '血氧',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },

        defaultSportDataChartConfig: {
          series: [
              {
                  name: '运动量',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },

        config: {
          radius: '40%',
          activeRadius: '45%',
          data: [
            {
              name: '周口',
              value: 55
            },
            {
              name: '南阳',
              value: 120
            },
            {
              name: '西峡',
              value: 78
            },
            {
              name: '驻马店',
              value: 66
            },
            {
              name: '新乡',
              value: 80
            }
          ],
          digitalFlopStyle: {
            fontSize: 20
          },
          showOriginValue: true
        },
        config1: {
          data: [
            {
              name: '1000步',
              value: 55
            },
            {
              name: '3000步',
              value: 120
            },
            {
              name: '6000步',
              value: 78
            }
          ]
        },
        config2: {
          data: [
            {
              name: '周口',
              value: 55123
            },
            {
              name: '南阳',
              value: 12022
            },
            {
              name: '西峡',
              value: 78932
            },
            {
              name: '驻马店',
              value: 63411
            },
            {
              name: '新乡',
              value: 44231
            }
          ],
          unit: '单位',
          showValue: true,
          valueFormatter ({ value }) {
            console.warn(arguments)
            const reverseNumber = (value + '').split('').reverse()
            let valueStr = ''

            while (reverseNumber.length) {
              const seg = reverseNumber.splice(0, 3).join('')
              valueStr += seg
              if (seg.length === 3) valueStr += ','
            }

            return valueStr.split('').reverse().join('')
          }
        },
        option: {
          series: [
              {
                  name: '睡眠质量',
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: [
                      {value: this.random(300, 400), name: '睡眠质量高'},
                      {value: this.random(200, 300), name: '睡眠质量中'},
                      {value: this.random(100, 200), name: '睡眠质量低'}
                  ],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        },
        options: {

          tooltip : {
              trigger: 'item'
          },
          dataRange: {
              min: 0,
              max: 2500,
              x: 'left',
              y: 'bottom',
              text:['高','低'],
              calculable : true
          },
          roamController: {
              show: true,
              x: 'right',
              mapTypeControl: {
                  'china': true
              }
          },
          series : [
              {
                  name: '患者数量',
                  type: 'map',
                  mapType: 'china',
                  roam: false,
                  itemStyle:{
                      normal:{label:{show:true}},
                      emphasis:{label:{show:true}}
                  },
                  data:[
                      {name: '北京',value: Math.round(Math.random()*1000)},
                      {name: '天津',value: Math.round(Math.random()*1000)},
                      {name: '上海',value: Math.round(Math.random()*1000)},
                      {name: '重庆',value: Math.round(Math.random()*1000)},
                      {name: '河北',value: Math.round(Math.random()*1000)},
                      {name: '河南',value: Math.round(Math.random()*1000)},
                      {name: '云南',value: Math.round(Math.random()*1000)},
                      {name: '辽宁',value: Math.round(Math.random()*1000)},
                      {name: '黑龙江',value: Math.round(Math.random()*1000)},
                      {name: '湖南',value: Math.round(Math.random()*1000)},
                      {name: '安徽',value: Math.round(Math.random()*1000)},
                      {name: '山东',value: Math.round(Math.random()*1000)},
                      {name: '新疆',value: Math.round(Math.random()*1000)},
                      {name: '江苏',value: Math.round(Math.random()*1000)},
                      {name: '浙江',value: Math.round(Math.random()*1000)},
                      {name: '江西',value: Math.round(Math.random()*1000)},
                      {name: '湖北',value: Math.round(Math.random()*1000)},
                      {name: '广西',value: Math.round(Math.random()*1000)},
                      {name: '甘肃',value: Math.round(Math.random()*1000)},
                      {name: '山西',value: Math.round(Math.random()*1000)},
                      {name: '内蒙古',value: Math.round(Math.random()*1000)},
                      {name: '陕西',value: Math.round(Math.random()*1000)},
                      {name: '吉林',value: Math.round(Math.random()*1000)},
                      {name: '福建',value: Math.round(Math.random()*1000)},
                      {name: '贵州',value: Math.round(Math.random()*1000)},
                      {name: '广东',value: Math.round(Math.random()*1000)},
                      {name: '青海',value: Math.round(Math.random()*1000)},
                      {name: '西藏',value: Math.round(Math.random()*1000)},
                      {name: '四川',value: Math.round(Math.random()*1000)},
                      {name: '宁夏',value: Math.round(Math.random()*1000)},
                      {name: '海南',value: Math.round(Math.random()*1000)},
                      {name: '台湾',value: Math.round(Math.random()*1000)},
                      {name: '香港',value: Math.round(Math.random()*1000)},
                      {name: '澳门',value: Math.round(Math.random()*1000)}
                  ]
              }
          ]
        }
      }
    },

    computed: {

      ...mapGetters({
        currentRealtimeData: 'monitorScreen/currentRealtimeData',
        currentSummaryLevel1: 'monitorScreen/currentSummaryLevel1'
      }),

      sosDataTotalNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.sosData) {
          res.number = [this.currentSummaryLevel1.sosData.totalNum]
        }
        return res
      },

      sosDataTodayNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.sosData) {
          res.number = [this.currentSummaryLevel1.sosData.todayNum]
        }
        return res
      },

      sosDataWeekNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.sosData) {
          res.number = [this.currentSummaryLevel1.sosData.weekNum]
        }
        return res
      },

      sosDataMonthNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.sosData) {
          res.number = [this.currentSummaryLevel1.sosData.monthNum]
        }
        return res
      },

      sosDataTopData () {
        let res = JSON.parse(JSON.stringify(this.defaultCapsuleChartConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.sosData && this.currentSummaryLevel1.sosData.topData) {
          let tmp = {}
          this.currentSummaryLevel1.sosData.topData.forEach( element => {
            tmp.name = element.regionName,
            tmp.value = element.totalNum
            res.data.push(tmp)
          })
        }
        return res
      },

      patientDataTopData () {
        let res = JSON.parse(JSON.stringify(this.defaultCapsuleChartConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.patientData && this.currentSummaryLevel1.patientData.topData) {
          let tmp = {}
          this.currentSummaryLevel1.patientData.topData.forEach( element => {
            tmp.name = element.regionName,
            tmp.value = element.totalNum
            res.data.push(tmp)
          })
        }
        return res
      },

      reportDataTotalNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.reportData) {
          res.number = [this.currentSummaryLevel1.reportData.totalNum]
        }
        return res
      },

      reportDataHealthNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.reportData) {
          res.number = [this.currentSummaryLevel1.reportData.healthNum]
        }
        return res
      },

      reportDataEcgStandardNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.reportData) {
          res.number = [this.currentSummaryLevel1.reportData.ecgStandardNum]
        }
        return res
      },

      reportDataEcgExpertNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.reportData) {
          res.number = [this.currentSummaryLevel1.reportData.ecgExpertNum]
        }
        return res
      },

      reportDataTopData () {
        let res = JSON.parse(JSON.stringify(this.defaultCapsuleChartConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.patientData && this.currentSummaryLevel1.reportData.topData) {
          let tmp = {}
          this.currentSummaryLevel1.reportData.topData.forEach( element => {
            tmp.name = element.regionName,
            tmp.value = element.totalNum
            res.data.push(tmp)
          })
        }
        return res
      },

      realtimeDataPatientNum  () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentRealtimeData && this.currentRealtimeData.patientNum) {
          res.number = [this.currentRealtimeData.patientNum]
        }
        return res
      },

      realtimeDataDeviceNum () {
        let res = JSON.parse(JSON.stringify(this.defaultDigitalFlopConfig))
        if (this.currentRealtimeData && this.currentRealtimeData.deviceNum) {
          res.number = [this.currentRealtimeData.deviceNum]
        }
        return res
      },

      equOnlineTrendsData() {
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.trendsData && this.currentSummaryLevel1.trendsData.equOnlineTrendsData) {
          return this.currentSummaryLevel1.trendsData.equOnlineTrendsData
        }
        return []
      },

      patientTrendsData() {
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.trendsData && this.currentSummaryLevel1.trendsData.equOnlineTrendsData) {
          return this.currentSummaryLevel1.trendsData.patientTrendsData
        }
        return []
      },

      bloodPressureData() {
        let res = JSON.parse(JSON.stringify(this.defaultBloodPressureDataChartConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.bloodPressureData) {
          res.series[0].data.push( { name: '重度高血压', value: this.currentSummaryLevel1.bloodPressureData.higherNum} )
          res.series[0].data.push( { name: '中度高血压', value: this.currentSummaryLevel1.bloodPressureData.middleNum} )
          res.series[0].data.push( { name: '轻度高血压', value: this.currentSummaryLevel1.bloodPressureData.slightNum} )
          res.series[0].data.push( { name: '临界高血压', value: this.currentSummaryLevel1.bloodPressureData.boundaryNum} )
          res.series[0].data.push( { name: '正常', value: this.currentSummaryLevel1.bloodPressureData.normalNum} )

        }
        return res
      },

      bloodOxygenData() {
        let res = JSON.parse(JSON.stringify(this.defaultBloodOxygenDataChartConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.bloodOxygenData) {
          res.series[0].data.push( { name: '供氧不足', value: this.currentSummaryLevel1.bloodOxygenData.middleNum} )
          res.series[0].data.push( { name: '低血氧症', value: this.currentSummaryLevel1.bloodOxygenData.lowerNum} )
          res.series[0].data.push( { name: '正常', value: this.currentSummaryLevel1.bloodOxygenData.normalNum} )

        }
        return res
      },

      sportData () {
        let res = JSON.parse(JSON.stringify(this.defaultSportDataChartConfig))
        if (this.currentSummaryLevel1 && this.currentSummaryLevel1.sportData) {
          res.series[0].data.push( { name: '大于6000步', value: this.currentSummaryLevel1.sportData.stepLevelOne} )
          res.series[0].data.push( { name: '大于3000步', value: this.currentSummaryLevel1.sportData.stepLevelTwo} )
          res.series[0].data.push( { name: '大于1000步', value: this.currentSummaryLevel1.sportData.stepLevelThree} )
          res.series[0].data.push( { name: '小于1000步', value: this.currentSummaryLevel1.sportData.stepLevelFour} )

        }
        return res
      }

    },

    created () {
      this.queryMonitorRealtimeData()
      this.queryMonitorSummaryLevel1()
    },

    methods: {

      ...mapActions({
        queryMonitorRealtimeData: 'monitorScreen/queryMonitorRealtimeData',
        queryMonitorSummaryLevel1: 'monitorScreen/queryMonitorSummaryLevel1'
      }),

      random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      }
    }
  }
</script>
<style type="scss">
.echarts {
  width: 100%;
  height: 100%;
}
</style>
